@import "@ahlsell-group/ui-kit-fonts";
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

/*
 * Starting in Firefox 105, there is a bug where a video element gets the wrong
 * height. The following element shoud have height=0px, but in buggy browser
 * versions it gets height=150px.
 *
 * <video height="0" style="height:auto">
 *
 * This causes a problem for us when the Scandit scanner is *not* active. When
 * it is inactive, an invisible <video> element is placed toward the bottom of
 * the screen. When its height is 0px, this is not a problem, but in Firefox it
 * causes all screens to be scrollable (showing only extra whitespace).
 *
 * https://bugzilla.mozilla.org/show_bug.cgi?id=1792414
 */
video[height="0"] {
  height: 0;
}
